import classNames from 'classnames'
import Icon from '~/components/Ui/Icon'

const CheckBox = ({ checked }) => (
  <div className={classNames('CheckBox', { checked })}>
    <Icon color="white" type="check-big" />
  </div>
)

CheckBox.defaultProps = {
  checked: false,
}
CheckBox.propTypes = {}

export default CheckBox
