import { NavLink } from '@remix-run/react'
import { useSite } from '~/context/site'
import Navigation from '~/components/Atoms/Navigation'
import Logo from '~/components/Atoms/Logo'
import Hamburger from '~/components/Atoms/Hamburger'

const Header = () => {
  const { url } = useSite('settings')

  return (
    <div className="Header">
      <div className="logo">
        <NavLink to={url?.relative}>
          <Logo />
        </NavLink>
      </div>
      <div className="hamburger">
        <Hamburger />
      </div>
      <Navigation items={[]} />
    </div>
  )
}

Header.defaultProps = {}
Header.propTypes = {}

export default Header
