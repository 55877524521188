import { motion, AnimatePresence } from 'framer-motion'
import { useState, useEffect } from 'react'
import consent from '~/services/consent'
import Text from '~/components/Ui/Text'
import Spacer from '~/components/Ui/Spacer'
import Center from '~/components/Ui/Center'
import Button from '~/components/Ui/Button'
import Form from '~/components/Consent/Form'

const Box = () => {
  const [show, setShow] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const onClickAcceptAll = () => {
    consent.all(true)
  }

  const onClickDenyAll = () => {
    consent.all(false)
  }

  const onClickSave = () => {
    consent.save()
  }

  const onClickClose = () => {
    if (showSettings) {
      setShowSettings(false)

      if (consent.seen()) {
        setShow(false)
      }
    } else {
      setShowSettings(true)
    }
  }

  useEffect(() => {
    const onDone = () => {
      setShow(false)
    }
    const onShow = () => {
      setShow(true)

      if (consent.seen()) {
        setShowSettings(true)
      }
    }
    consent.on('done', onDone)
    consent.on('show', onShow)

    return () => {
      consent.off('done', onDone)
      consent.off('show', onShow)
    }
  }, [true])

  const title = consent.setting('title')
  const description = consent.setting('description')
  const buttons = consent.setting('buttons')

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          key="cookie-box"
          className="Cookie-Box"
          initial={{ y: '150%' }}
          animate={{ y: 0, transition: { delay: 0.1 } }}
          exit={{ y: '150%' }}
        >
          <Text as="div" type="caption" center color="dark">
            {title}
          </Text>
          <Text as="div" className="header" type="text-xs" color="dark" center html>
            {description}
          </Text>

          <motion.div
            className="form"
            initial="closed"
            animate={showSettings ? 'open' : 'closed'}
            variants={{
              open: { height: 'auto' },
              closed: { height: 0 },
            }}
          >
            <Form />
            <Center className="save">
              <Spacer scale="5" />
              <Button small type="dark-outline" textType="text-xs" onClick={onClickSave}>
                {buttons.save}
              </Button>
              <Spacer scale="5" />
            </Center>
          </motion.div>

          <Spacer scale="4" />
          <div className="buttons">
            <Button small type="dark-outline" textType="text-xs" onClick={onClickClose}>
              {showSettings ? 'Schließen' : buttons.settings}
            </Button>
            <Button small type="dark-outline" textType="text-xs" onClick={onClickDenyAll}>
              {buttons.deny_all}
            </Button>

            <Button small type="dark" textType="text-xs" onClick={onClickAcceptAll}>
              {buttons.accept_all}
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

Box.defaultProps = {}
Box.propTypes = {}

export default Box
