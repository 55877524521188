import { useState, useEffect } from 'react'
import { useLocation } from '@remix-run/react'
import useMouse from '@react-hook/mouse-position'
import { motion } from 'framer-motion'
import { useSiteState } from '~/context/state'
import Icon from '~/components/Ui/Icon'

const Cursor = () => {
  const { cursorType, setCursor } = useSiteState()
  const [iconType, setIconType] = useState('cursor')
  const [iconColor, setIconColor] = useState('brand')
  const [last, setLast] = useState([0, 0])
  const location = useLocation()

  const cursorSize = 80

  const { x, clientX, y, clientY } = useMouse(
    typeof window !== 'undefined' ? document?.body : null,
    {
      enterDelay: 100,
      leaveDelay: 100,
    }
  )

  const isInside = x !== null && y !== null
  const mouseX = x !== null ? clientX : last[0] || 0
  const mouseY = y !== null ? clientY : last[1] || 0

  const varDefaults = {
    small: {
      opacity: isInside ? 1 : 0,
      height: cursorSize / 2,
      width: cursorSize / 2,
      x: mouseX - cursorSize / 4 + 4,
      y: mouseY - cursorSize / 4 + 5,
    },
    big: {
      opacity: isInside ? 1 : 0,
      height: cursorSize,
      width: cursorSize,
      x: mouseX - cursorSize / 2 + 8,
      y: mouseY - cursorSize / 2 + 10,
    },
  }

  const variants = {
    default: { ...varDefaults.small },
    project: { ...varDefaults.big, ...{ backgroundColor: '#ff4f1d' } },
    navigation: { ...varDefaults.small },
    link: { ...varDefaults.big, ...{ backgroundColor: '#ff4f1d' } },
    mail: { ...varDefaults.big, ...{ backgroundColor: '#ff4f1d' } },
    phone: { ...varDefaults.big, ...{ backgroundColor: '#ff4f1d' } },
    location: { ...varDefaults.big, ...{ backgroundColor: '#ff4f1d' } },
  }

  const transition = {
    type: 'tween',
    duration: 0.05,
  }

  useEffect(() => {
    if (mouseX && mouseY) {
      setLast([mouseX, mouseY])
    }
  }, [mouseX, mouseY])

  useEffect(() => {
    if (cursorType === 'default') {
      setIconType('cursor')
      setIconColor('brand')
    }

    if (cursorType === 'navigation') {
      setIconType('cursor')
      setIconColor('white')
    }

    if (cursorType === 'project') {
      setIconType('plus')
      setIconColor('white')
    }

    if (cursorType === 'link') {
      setIconType('arrow-right')
      setIconColor('white')
    }

    if (cursorType === 'mail') {
      setIconType('mail-outlines')
      setIconColor('white')
    }

    if (cursorType === 'phone') {
      setIconType('phone-outlines')
      setIconColor('white')
    }

    if (cursorType === 'location') {
      setIconType('pin-outlines')
      setIconColor('white')
    }
  }, [cursorType])

  useEffect(() => {
    setCursor()
  }, [location])

  return (
    <motion.div variants={variants} className="Cursor" animate={cursorType} transition={transition}>
      <Icon type={iconType} color={iconColor} />
    </motion.div>
  )
}

Cursor.defaultProps = {}
Cursor.propTypes = {}

export default Cursor
