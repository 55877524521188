import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion, useAnimation } from 'framer-motion'
import { useMedia } from '~/context/media'
import { useSiteState } from '~/context/state'

const Hamburger = () => {
  const desktop = useMedia('md')
  const { navigation: isOpen, toggleNavigation } = useSiteState()
  const lineTopControls = useAnimation()
  const lineBottomControls = useAnimation()
  const [hover, setHover] = useState(false)

  const hide = async () => {
    await Promise.all([
      lineTopControls.start({
        width: '50%',
        backgroundColor: '#ffffff',
      }),
      lineBottomControls.start({
        width: '50%',
        backgroundColor: '#ffffff',
      }),
    ])

    await Promise.all([lineTopControls.start({ y: 9 }), lineBottomControls.start({ y: -9 })])

    await Promise.all([
      lineTopControls.start({ rotate: 45 }),
      lineBottomControls.start({ rotate: -45 }),
    ])
  }

  const show = async () => {
    await Promise.all([
      lineTopControls.start({ rotate: 0 }),
      lineBottomControls.start({ rotate: -0 }),
    ])

    await Promise.all([lineTopControls.start({ y: 0 }), lineBottomControls.start({ y: 0 })])

    await Promise.all([
      lineTopControls.start({
        width: hover && desktop ? '50%' : '100%',
        backgroundColor: '#ff501e',
      }),
      lineBottomControls.start({
        width: '50%',
        backgroundColor: '#ff501e',
      }),
    ])
  }

  const enter = async () => {
    setHover(true)
    lineTopControls.start({
      width: '50%',
    })
  }

  const exit = async () => {
    setHover(false)
    lineTopControls.start({
      width: isOpen ? '50%' : '100%',
    })
  }

  const onClick = async () => {
    toggleNavigation()
  }

  const onMouseEnter = async () => {
    enter()
  }

  const onMouseLeave = async () => {
    exit()
  }

  useEffect(() => {
    if (!isOpen) {
      show()
    } else {
      hide()
    }
  }, [isOpen])

  return (
    <div
      className={classNames('Hamburger')}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <motion.div
        className={'lineTop'}
        animate={lineTopControls}
        transition={{ type: 'spring', bounce: 0, duration: 0.1 }}
      />
      <motion.div
        className={'lineBottom'}
        animate={lineBottomControls}
        transition={{ type: 'spring', bounce: 0, duration: 0.1 }}
      />
    </div>
  )
}

Hamburger.defaultProps = {}
Hamburger.propTypes = {}

export default Hamburger
