import classNames from 'classnames'
import Icon from '~/components/Ui/Icon'
import tracker from '~/services/tracker'

const SocialIcons = ({ items, type }) => (
  <div className="SocialIcons">
    {items.map((item, idx) => (
      <a
        href={item.link.url}
        key={item.icon}
        target="_blank"
        rel="noreferrer"
        className={classNames('holder', `type-${type}`)}
        onClick={() => tracker.event('lead', 'click.social', item.icon)}
      >
        <Icon type={item.icon} color="inherit" />
      </a>
    ))}
  </div>
)

SocialIcons.defaultProps = {
  type: 'background',
}
SocialIcons.propTypes = {}

export default SocialIcons
