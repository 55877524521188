import { useEffect, useState, useRef } from 'react'
import { motion, animate } from 'framer-motion'
import classNames from 'classnames'
import { lock, unlock } from 'tua-body-scroll-lock'
import { NavLink } from '@remix-run/react'
import Text from '~/components/Ui/Text'
import Spacer from '~/components/Ui/Spacer'
import SocialIcons from '~/components/Atoms/SocialIcons'
import Logo from '~/components/Atoms/Logo'
import LanguagePicker from '~/components/Atoms/LanguagePicker'
import TextHover from '~/components/Effects/TextHover'
import { useNavigation, useSite, useStrings } from '~/context/site'
import { useSiteState } from '~/context/state'
import { useMedia } from '~/context/media'

const Navigation = () => {
  const menu = useNavigation('main_navigation', 'location')
  const { socials } = useSite('settings')
  const { available } = useSite('lang')
  const strings = useStrings()
  const { navigation: isOpen, setCursor, toggleNavigation } = useSiteState()
  const [waves, setWaves] = useState([0, 0])
  const isDesktop = useMedia('md')
  const navRef = useRef()
  const svgRef = useRef()

  useEffect(() => {
    const onBeforeOpen = () => {
      if (navRef?.current && navRef?.current?.style) {
        navRef.current.style.visibility = 'visible'
      }
      setCursor('navigation')
      lock()
    }

    const onAfterClose = () => {
      setCursor()
      if (navRef?.current && navRef?.current?.style) {
        navRef.current.style.visibility = 'hidden'
      }
      unlock()
    }

    const c01 = animate(isOpen ? 0 : 1, isOpen ? 1 : 0, {
      type: 'spring',
      bounce: 0.3,
      delay: !isOpen ? 0.2 : 0,
      onUpdate: v => setWaves(w => [v, w[1]]),
      onComplete: () => !isOpen && onAfterClose(),
    })

    const c02 = animate(isOpen ? 0 : 1, isOpen ? 1 : 0, {
      type: 'spring',
      bounce: 0,
      delay: !isOpen ? 0.2 : 0,
      onUpdate: v => setWaves(w => [w[0], v]),
    })

    if (isOpen) {
      onBeforeOpen()
    }

    return () => {
      if (isOpen !== null) {
        c01?.stop()
        c02?.stop()
      }
    }
  }, [isOpen])

  const itemVariants = {
    hidden: i => ({
      y: '100%',
      transition: { type: 'spring', bounce: 0, delay: 0.08 * (menu?.items?.length - i - 1) },
    }),
    visible: i => ({
      y: '0%',
      transition: { type: 'spring', bounce: 0, delay: 0.1 + 0.08 * i },
    }),
  }

  const socialVariants = {
    hidden: {
      y: '300%',
      transition: { type: 'spring', bounce: 0, delay: 0 },
    },
    visible: {
      y: '0%',
      transition: { type: 'spring', bounce: 0, delay: 0.6 },
    },
  }

  useEffect(() => {
    if (svgRef?.current) {
      svgRef.current.style.clipPath = 'none'
      svgRef.current.offsetWidth
      svgRef.current.style.clipPath = 'url(#wave-mask)'
    }
  }, waves)

  return (
    <div className={classNames('Navigation', { open: isOpen })} ref={navRef}>
      <svg className="waves">
        <defs>
          <clipPath clipPathUnits="objectBoundingBox" id="wave-mask">
            <motion.path
              d={`M 0 ${waves[0]} C 0.5 ${waves[0]} 0.5 ${waves[1]} 1 ${waves[1]} V 0 H 0`}
            />
            <rect x="250" y="250" width="1" height="1" />
          </clipPath>
        </defs>
      </svg>
      <div className="overlay" ref={svgRef}>
        <div className="logo">
          <NavLink to="/" onClick={toggleNavigation}>
            <Logo color="white" />
          </NavLink>
        </div>
        <div className="box">
          <ul className="list">
            {menu?.items?.map((item, idx) => (
              <motion.li
                className="item"
                key={item.id}
                variants={itemVariants}
                custom={idx}
                animate={isOpen ? 'visible' : 'hidden'}
                initial="hidden"
              >
                <Text as="div" color="white" type="text-navigation" className="text">
                  <TextHover
                    from={{ fontWeight: 700, width: '100%' }}
                    to={{ fontWeight: 725, width: '100%' }}
                  >
                    <NavLink
                      to={item.url.relative}
                      onClick={toggleNavigation}
                      className="link"
                      target={item.target || '_self'}
                    >
                      {({ isActive }) => (
                        <>
                          <span className="title">{item.title}</span>
                          {isDesktop && isActive && (
                            <Text as="span" className="is-active" type="caption" color="white">
                              {strings['navigation.indicator']}
                            </Text>
                          )}
                        </>
                      )}
                    </NavLink>
                  </TextHover>
                </Text>
                {isDesktop && <div className="line" />}
              </motion.li>
            ))}
          </ul>
          <Spacer scale="10" vertical />
          <div className="footer">
            <motion.div
              className="socials"
              variants={socialVariants}
              animate={isOpen ? 'visible' : 'hidden'}
              initial="hidden"
            >
              <SocialIcons items={socials} type="plain" />
            </motion.div>
            <motion.div
              className="socials"
              variants={socialVariants}
              animate={isOpen ? 'visible' : 'hidden'}
              initial="hidden"
            >
              <LanguagePicker items={Object.values(available)} />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation
