import { LiveReload, Scripts, ScrollRestoration } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { usePage } from '~/context/page'
import { useDebug } from '~/context/debug'
import useWindowSize from '~/hooks/use-window-size'
import Sidebar from '~/components/Debug/Sidebar'
import Header from '~/components/Wrapper/Body/Header'
import Main from '~/components/Wrapper/Body/Main'
import Footer from '~/components/Wrapper/Body/Footer'
import Grid from '~/components/Ui/Grid'
import ClientOnly from '~/components/Helpers/ClientOnly'
import Cursor from '~/components/Atoms/Cursor'
import TagManager from '~/components/Helpers/TagManager'
import BrowserSupport from '~/components/Helpers/BrowserSupport'
import { Box as CookieBox } from '~/components/Consent'
import { isDevelopment } from '~/utils/helpers'
import { useMedia } from '~/context/media'
import config from '~/config'

const Body = () => {
  const { data } = usePage()
  const isTouch = useMedia('isTouch')
  const debug = useDebug()
  const size = useWindowSize()
  const [vhSet, setVhSet] = useState(false)

  useEffect(() => {
    if (size.height > 0) {
      if (!vhSet) {
        document.documentElement.style.setProperty('--vh', `${size.height * 0.01}px`)
        setVhSet(true)
      }
      document.documentElement.style.setProperty('--vh-dynamic', `${size.height * 0.01}px`)
    }
  }, [size])

  return (
    <body className="Body">
      <ClientOnly>
        <TagManager />
        <BrowserSupport />
      </ClientOnly>

      <CookieBox />

      {!isTouch && (
        <ClientOnly>
          <Cursor />
        </ClientOnly>
      )}
      <Header />
      <Main />
      <Footer />

      {debug?.grid && <Grid.Debug columns={14} />}
      {config.debug && <Sidebar />}

      <ScrollRestoration />
      <Scripts />
      {isDevelopment() && <LiveReload />}
    </body>
  )
}

Body.defaultProps = {}
Body.propTypes = {}

export default Body
