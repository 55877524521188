import Text from '~/components/Ui/Text'
import config from '~/config'

const LanguagePicker = ({ items }) => {
  if (!config.lang.showSwitcher) {
    return null
  }

  return (
    <ul className="LanguagePicker">
      {items.map(item => (
        <li key={item.slug} className={item?.current ? 'current' : ''}>
          <Text
            as="a"
            href={`${item?.url?.absolute}?${config.lang.switchQueryString}=${item.slug}`}
            type="caption"
            color="white"
          >
            {item.slug}
          </Text>
        </li>
      ))}
    </ul>
  )
}

LanguagePicker.defaultProps = {}
LanguagePicker.propTypes = {}

export default LanguagePicker
