import { useEffect, useState } from 'react'
import consent from '~/services/consent'
import Category from '~/components/Consent/Form/Category'

const Form = () => {
  const [data, setData] = useState(consent.data())
  const [user, setUser] = useState(consent.user())

  useEffect(() => {
    consent.on('init', setData)
    consent.on('user', setUser)

    return () => {
      consent.off('init', setData)
      consent.off('user', setUser)
    }
  }, [true])

  return (
    <div className="Form">
      {data.map(category => (
        <Category key={category.slug} category={category} user={user} />
      ))}
    </div>
  )
}

Form.defaultProps = {}
Form.propTypes = {}

export default Form
