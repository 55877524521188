import { Link } from '@remix-run/react'
import Grid from '~/components/Ui/Grid'
import Text from '~/components/Ui/Text'
import Inner from '~/components/Ui/Inner'
import SocialIcons from '~/components/Atoms/SocialIcons'
import { useSite, useNavigation } from '~/context/site'
import tracker from '~/services/tracker'

const Footer = () => {
  const { contact, socials } = useSite('settings')
  const menu = useNavigation('footer_navigation', 'location')
  const year = new Date().getFullYear()

  return (
    <Inner className="Footer">
      <Grid.Row>
        <Grid.Column size="12/12 2/14@md" className="column maps with-icon">
          <Text
            as="a"
            href={contact?.maps}
            target="_blank"
            type="text-xs"
            color="white-50"
            onClick={() => tracker.event('lead', 'click.maps', 'footer')}
          >
            &copy;{` ${year} - ${contact?.company}`}
          </Text>
        </Grid.Column>

        <Grid.Column size="12/12 2/14@md" className="column email with-icon">
          <Text
            as="a"
            href={`mailto:${contact?.email}`}
            type="text-xs"
            color="white-50"
            onClick={() => tracker.event('lead', 'click.mail', 'footer')}
          >
            {contact?.email}
          </Text>
        </Grid.Column>

        <Grid.Column size="12/12 2/14@md" className="column phone with-icon">
          <Text
            as="a"
            href={`tel:${contact?.phoneRaw}`}
            type="text-xs"
            color="white-50"
            onClick={() => tracker.event('lead', 'click.phone', 'footer')}
          >
            {contact?.phone}
          </Text>
        </Grid.Column>

        <Grid.Column size="2/14@md" className="column social">
          <SocialIcons items={socials} type="background" />
        </Grid.Column>

        <a name="cookies" id="cookies" />
        {menu?.items?.map(item => (
          <Grid.Column size="4/12 2/14@md" className="column nav" key={item.id}>
            <Text type="text-xs" color="white-50">
              {!item.external && (
                <Link
                  to={item.url.relative}
                  target={item.target || '_self'}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              )}
              {item.external && (
                <a
                  href={item.url.absolute}
                  target={item.target || '_self'}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              )}
            </Text>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Inner>
  )
}

Footer.defaultProps = {}
Footer.propTypes = {}

export default Footer
