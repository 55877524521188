// import { AnimatePresence, motion } from 'framer-motion'
import { useOutlet } from '@remix-run/react'

/* eslint-disable */
const Main = () => {
  const outlet = useOutlet()
  // const location = useLocation()
  
  return (
    <div className="Main">
      {outlet}
    </div>
  )
}

Main.defaultProps = {}
Main.propTypes = {}

export default Main
