import { Links, Meta, useMatches } from '@remix-run/react'

const Head = () => {
  const matches = useMatches()
  const canonical = matches.find(m => m?.data?.canonical)?.data?.canonical || false
  // const seo = matches.find(m => m?.data?.seo)?.data?.seo || false
  const alternates = matches.find(m => m?.data?.lang?.alternate)?.data?.lang?.alternate || []

  // console.log(seo)
  // const schema = {
  //   '@context': 'https://schema.org',
  //   '@graph': [
  //     {
  //       '@type': 'WebSite',
  //       '@id': 'https://probots.io/#website',
  //       url: 'https://probots.io/',
  //       name: 'Conversory',
  //       description: 'Converting visions to digital reality',
  //       inLanguage: 'de-DE',
  //     },
  //     {
  //       '@type': 'WebPage',
  //       '@id': 'https://probots.io/#webpage',
  //       url: 'https://probots.io/',
  //       name: 'Conversory — Converting visions to digital reality',
  //       isPartOf: {
  //         '@id': 'https://probots.io/#website',
  //       },
  //       datePublished: '2021-07-20T14:36:04+00:00',
  //       dateModified: '2022-07-23T11:22:48+00:00',
  //       description:
  //         'Als Agentur für digitales Marketing positionieren wir visionäre Unternehmen, Marken, Produkte als auch Dienstleistungen im Netz und entwickeln smarte Software.',
  //       breadcrumb: {
  //         '@id': 'https://probots.io/#breadcrumb',
  //       },
  //       inLanguage: 'de-DE',
  //       potentialAction: [
  //         {
  //           '@type': 'ReadAction',
  //           target: ['https://probots.io/'],
  //         },
  //       ],
  //     },
  //   ],
  // }

  return (
    <head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1.0, initial-scale=1.0, user-scalable=yes"
      />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <Meta />
      {canonical && <link rel="canonical" href={canonical} />}
      <link rel="alternate" hrefLang="x-default" href="https://probotsio/" />
      {alternates &&
        Object.values(alternates).map(alt => (
          <link key={alt?.slug} rel="alternate" hrefLang={alt?.locale} href={alt?.url?.relative} />
        ))}

      <Links />
    </head>
  )
}

Head.defaultProps = {}
Head.propTypes = {}

export default Head
