import Site from '~/components/Wrapper/Site'
import Head from '~/components/Wrapper/Head'
import ErrorComp from '~/components/Helpers/Error'

const ErrorGeneral = ({ status, title, message, debug }) => (
  <Site>
    <Head />
    <body>
      <div className="ErrorGeneral">
        <ErrorComp title={title} message={message} debug={debug} />
      </div>
    </body>
  </Site>
)

ErrorGeneral.defaultProps = {
  status: null,
  title: 'Oh no!',
  message: 'Something went wrong!',
}
ErrorGeneral.propTypes = {}

export default ErrorGeneral
