import { useState } from 'react'
import classNames from 'classnames'
import { useDebug } from '~/context/debug'
import { usePage } from '~/context/page'
import { useSite } from '~/context/site'
import Json from '~/components/Helpers/Json'
import DebugText from '~/components/Ui/Text/Debug'

const Sidebar = () => {
  const debug = useDebug()
  const { data: pageState } = usePage()
  const { data: siteState } = useSite()
  const [show, setShow] = useState(false)

  return (
    <div className={classNames('Debug-Sidebar', { show })}>
      <button className="close" onClick={() => setShow(!show)}>
        ⇔
      </button>
      <div className="inner">
        <h3>Tools</h3>
        <ul>
          <li onClick={() => debug.set('grid')}>Grid: {debug.grid ? 'On' : 'Off'}</li>
          <li onClick={() => debug.set('components')}>
            Components: {debug.components ? 'On' : 'Off'}
          </li>
          <li onClick={() => debug.set('spacer')}>Spacer: {debug.spacer ? 'On' : 'Off'}</li>
        </ul>

        <h3>Site State</h3>
        <Json json={siteState} />

        <h3>Page State</h3>
        <Json json={pageState} />

        <h3>Text Styles</h3>
        <DebugText />
      </div>
    </div>
  )
}

Sidebar.defaultProps = {}
Sidebar.propTypes = {}

export default Sidebar
