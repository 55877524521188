import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import consent from '~/services/consent'
import CheckBox from '~/components/Atoms/CheckBox'
import Text from '~/components/Ui/Text'

const Provider = ({ category, provider, user }) => {
  const [show, setShow] = useState(false)

  return (
    <div className="Provider">
      <div className="checkbox" onClick={() => consent.toggle(category.slug, provider.slug)}>
        <CheckBox checked={user[[category.slug, provider.slug].join('.')]} />
      </div>
      <Text as="div" type="text-s" className="name" color="dark">
        {provider.name}{' '}
        {provider?.cookies?.length > 0 && (
          <Text as="div" type="text-xxs" onClick={() => setShow(!show)} color="dark" underline>
            {show ? 'Schließen' : 'Cookie Details'}
          </Text>
        )}
      </Text>
      <Text as="div" type="text-xs" className="description" color="dark-80">
        Provider: {provider.provider}
        <br />
        {provider.description}
      </Text>

      <motion.div
        className="cookies"
        initial="closed"
        animate={show ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto' },
          closed: { height: 0 },
        }}
      >
        {provider?.cookies?.map((cookie, idx) => (
          <div key={cookie.name + idx} className="cookie">
            <Text as="div" type="text-xxs" className="name" color="dark">
              {cookie.name}
            </Text>
            <Text as="div" type="text-xxs" className="description" color="dark">
              {cookie.description}
            </Text>
            <Text as="div" type="text-xxs" className="type" color="dark">
              {cookie.type}
            </Text>
            <Text as="div" type="text-xxs" className="expires" color="dark">
              {cookie.expires}
            </Text>
          </div>
        ))}
      </motion.div>
    </div>
  )
}

Provider.defaultProps = {}
Provider.propTypes = {}

export default Provider
