import SiteContextProvider from '~/context/site'
import StateContextProvider from '~/context/state'
import MediaQueryProvider from '~/context/media'
import PageContextProvider from '~/context/page'
import DebugContextProvider from '~/context/debug'

const Providers = ({ children, site }) => (
  <DebugContextProvider>
    <SiteContextProvider value={site}>
      <StateContextProvider value={{}}>
        <MediaQueryProvider>
          <PageContextProvider value={{}}>{children}</PageContextProvider>
        </MediaQueryProvider>
      </StateContextProvider>
    </SiteContextProvider>
  </DebugContextProvider>
)

Providers.defaultProps = {
  site: {},
}
Providers.propTypes = {}

export default Providers
