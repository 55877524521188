import { useEffect } from 'react'
import { useLocation } from '@remix-run/react'
import consent from '~/services/consent'

const TagManager = () => {
  const id = consent.setting('tag_manager_id')
  const url = consent.setting('tag_manager_url')

  const location = useLocation()
  const src = `${url}/gtm.js?id=${id}`

  useEffect(() => {
    if (typeof document !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      const g = document.createElement('script')
      const s = document.getElementsByTagName('script')[0]

      g.async = true
      g.src = src

      s.parentNode.insertBefore(g, s)
    }
  }, [true])

  return null
}

TagManager.defaultProps = {}
TagManager.propTypes = {}

export default TagManager
