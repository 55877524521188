const colors = {
  light: {
    light: '#fff',
    background: '#fff',
    darkBackground: '#08001e',
    lightBackground: '#f9f9f9',
    brand: '#ff4f1d',
    brandLight: '#ff744b',
    brandLightest: '#ffede9',
    brandAlt: '#08001e',
    border: 'rgb(255 255 255 / 20%)',
    borderDark: 'rgb(8 0 30 / 20%)',
    textBrand: '#ff4f1d',
    textWhite: '#fff',
    textWhite50: 'rgb(255 255 255 / 50%)',
    textDark: '#08001e',
    textDark50: 'rgb(8 0 30 / 50%)',
    textDark80: 'rgb(8 0 30 / 80%)',
  },
  dark: {
    light: '#fff',
    background: '#08001e',
    darkBackground: '#08001e',
    lightBackground: '#08001e',
    brand: '#ff4f1d',
    brandLight: '#ff744b',
    brandLightest: '#ffede9',
    brandAlt: '#08001e',
    border: 'rgb(255 255 255 / 20%)',
    borderDark: 'rgb(255 255 255 / 20%)',
    textBrand: '#ff4f1d',
    textWhite: '#ffffff',
    textWhite50: 'rgb(255 255 255 / 50%)',
    textDark: '#fff',
    textDark50: 'rgb(255 255 255 / 50%)',
    textDark80: 'rgb(255 255 255 / 80%)',
  },
}

export default {
  dark: () => {
    Object.keys(colors.dark).forEach(k => {
      document.documentElement.style.setProperty(`--color-${k}`, colors.dark[k])
    })
  },
  light: () => {
    Object.keys(colors.light).forEach(k => {
      document.documentElement.style.setProperty(`--color-${k}`, colors.light[k])
    })
  },
}
