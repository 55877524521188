import Text from '~/components/Ui/Text'

const Logo = ({ color }) => (
  <Text as="div" type="logo" color={color}>
    probots
  </Text>
)

Logo.defaultProps = {
  color: 'brand',
}
Logo.propTypes = {}

export default Logo
