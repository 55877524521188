import { useEffect } from 'react'

const BrowserSupport = () => {
  const config = {
    required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
    reminder: 0,
    reminderClosed: 150,
    l: 'de',
    no_permanent_hide: true,
    insecure: true,
    unsupported: true,
    api: 2022.05,
    test: false,
  }
  useEffect(() => {
    if (typeof document !== 'undefined') {
      window.$buoop = config
      const g = document.createElement('script')
      const s = document.getElementsByTagName('script')[0]

      g.async = true
      g.src = '//browser-update.org/update.min.js'

      s.parentNode.insertBefore(g, s)
    }
  }, [true])
  return null
}

BrowserSupport.defaultProps = {}
BrowserSupport.propTypes = {}

export default BrowserSupport
