import { useEffect } from 'react';
import { isRouteErrorResponse, useLoaderData, useLocation, useRouteError } from '@remix-run/react';
import { json } from '@remix-run/cloudflare';
import { getSite } from '~/server/loader';
import Lang from '~/lang/index.server';
import { cache, cookie, lang } from '~/server/middleware';
import cookieService from '~/services/cookie';
import Head from '~/components/Wrapper/Head';
import Body from '~/components/Wrapper/Body';
import Site from '~/components/Wrapper/Site';
import Providers from '~/components/Helpers/Providers';
import ErrorGeneral from '~/components/Pages/ErrorGeneral';
// import Error404 from '~/components/Pages/Error404'
import tracker from '~/services/tracker';
// import metaData from '~/generated/meta.json'
import consent from '~/services/consent';
import colors from '~/services/colors';
// import css from '~/static/index.css'

export const loader = async (props) => {
  await cookie(props);
  await cache(props);
  await lang(props);

  return json(
    {
      site: await getSite(),
      lang: Lang.getCurrent()
    },
    {
      headers: {
        'Set-Cookie': await cookieService.serialize()
      }
    }
  );
};

// export const meta = () => [{ tagName: 'link', rel: 'stylesheet', href: css }, ...metaData]


export function ErrorBoundary() {
  const error = useRouteError();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return (
      <ErrorGeneral status={null} message={`${error?.status}: ${error.data?.message}`} debug={error?.stack} />);

  }

  let errorMessage = error.message || 'Unknown error';

  return (
    <ErrorGeneral status={null} message={`${error?.status}: ${errorMessage}`} debug={error?.stack} />);

}

//
// export const ErrorBoundary = ({ error }) => (
//   <ErrorGeneral status={null} message={`${error?.name}: ${error.message}`} debug={error?.stack} />
// )
//
// export const CatchBoundary = f => {
//   const caught = useCatch()
//   const { site } = useLoaderData()
//
//   if (caught?.status === 404) {
//     return (
//       <Providers site={site}>
//         <Error404 />
//       </Providers>
//     )
//   }
//
//   return <ErrorGeneral status={caught?.status} title={caught?.data} message={caught?.statusText} />
// }

const App = () => {
  const { site, lang: currentLang } = useLoaderData();
  const location = useLocation();

  useEffect(() => {
    tracker.init();
    consent.init(site.cookies);

    window.dark = () => {
      colors.dark();
    };
    window.light = () => {
      colors.light();
    };
  }, [true]);

  useEffect(() => {
    tracker.send('page-view-plain', location, true);
    tracker.send('page-view', location);

    if (location?.hash === '#cookies') {
      consent.show();
    }
  }, [location]);

  return (
    <Providers site={site}>
      <Site lang={currentLang}>
        <Head />
        <Body />
      </Site>
    </Providers>);

};

export default App;