import { useState } from 'react'
import { motion } from 'framer-motion'
import consent from '~/services/consent'
import CheckBox from '~/components/Atoms/CheckBox'
import Text from '~/components/Ui/Text'
import Provider from '~/components/Consent/Form/Provider'

const Category = ({ category, user }) => {
  const [show, setShow] = useState(false)

  return (
    <div className="Category">
      <div className="info">
        <div className="checkbox" onClick={() => consent.toggle(category.slug)}>
          <CheckBox checked={user[category.slug]} />
        </div>
        <Text as="div" type="text-m" className="name" color="dark">
          {category.name}{' '}
          <Text as="div" type="text-xs" onClick={() => setShow(!show)} color="dark" underline>
            {show ? 'Schließen' : 'Details'}
          </Text>
        </Text>
        <Text as="div" type="text-xs" className="description" color="dark-80">
          {category.description}
        </Text>
      </div>

      <motion.div
        className="details"
        initial="closed"
        animate={show ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto' },
          closed: { height: 0 },
        }}
      >
        {category?.providers?.map(provider => (
          <Provider key={provider.slug} provider={provider} user={user} category={category} />
        ))}
      </motion.div>
    </div>
  )
}

Category.defaultProps = {}
Category.propTypes = {}

export default Category
