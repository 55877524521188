import Text from '~/components/Ui/Text'

const Debug = () => {
  const types = [
    'text-xxs',
    'text-xs',
    'text-s',
    'text-m',
    'text-ms',
    'text-l',
    'text-xl',
    'text-xxl',
    'text-xxl-hover',
    'caption',
    'button',
  ]

  const colors = ['brand', 'white', 'white-50', 'dark', 'dark-50', 'dark-80']

  return (
    <div className="Debug-Text">
      {types.map((type, i) => (
        <Text as="div" key={i} type={type} color="dark" className="block">
          {type}
        </Text>
      ))}

      {colors.map((color, i) => (
        <Text as="div" key={i} type="text-xl" color={color} className="block">
          {color}
        </Text>
      ))}
    </div>
  )
}

Debug.defaultProps = {}
Debug.propTypes = {}

export default Debug
